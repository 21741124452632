import React from "react";

import { Tag } from "~components/Tag";

import * as styles from "../styles.css";

export interface CardTopicProps {
  topic: string;
}

export function CardTopic({ topic }: CardTopicProps) {
  return (
    <Tag
      tagSize="sm"
      title={topic}
      cardBackgroundVariant="background_white"
      className={styles.getTopicTagStyle}
    />
  );
}
