import React from "react";

import { faCalendar } from "@fortawesome/sharp-regular-svg-icons/faCalendar";
import { faLocationPin } from "@fortawesome/sharp-regular-svg-icons/faLocationPin";

import { formatDate } from "~utils/__deprecated__/date_utils/format_date";

import { Box } from "~components/Box";
import { IconFontAwesome } from "~components/IconFontAwesome";
import { Tag } from "~components/Tag";

import * as styles from "../styles.css";

export interface CardInfoProps {
  date?: string | null;
  location?: string | null;
  topic?: string | null;
}

export function CardInfo({ date, location, topic }: CardInfoProps) {
  return (
    <Box display="flex" gap="spacing1" marginBottom="spacing1">
      {topic && (
        <Tag
          tagSize="sm"
          title={topic}
          cardBackgroundVariant="background_white"
          className={styles.getTopicTagStyle}
        />
      )}
      {date && (
        <Box
          color="text_lowContrast"
          gap="spacing1"
          display="flex"
          flexShrink="0"
          whiteSpace="nowrap"
          alignItems="center"
          textAppearance="body_sm"
        >
          <IconFontAwesome icon={faCalendar} /> {formatDate(date)}
        </Box>
      )}
      {location && (
        <Tag iconLeft={faLocationPin} tagSize="sm" title={location} />
      )}
    </Box>
  );
}
